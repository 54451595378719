import ModalCore from '@mui/material/Modal';
import SvgClose from 'assets/general/close.inline.svg';
import React from 'react';
import tw, { css } from 'twin.macro';

const cssModal = css`
	${tw`overflow-y-scroll my-10`};
`;

const cssElementP = css`
	p {
		${tw`leading-11 my-05`};
	}
  h1 {
    ${tw`my-15`};
  }
  h2 {
    ${tw`my-10`};
  }
  h3 {
    ${tw`my-08`};
  }
  h4 {
    ${tw`my-07`};
  }
  h5 {
    ${tw`my-06`};
  }
  h6 {
    ${tw`my-05`};
  }
`;

export default function Modal({ isOpen, children, onClose }) {
	return (
		<ModalCore
			css={[ cssModal ]}
			open={isOpen}
			onClose={onClose}
		>
			<div style={{ maxWidth: '62rem', outline: 'none' }} className='mx-auto'>
				<div data-cy="Modal" className='mx-10'>
					<div className='flex items-center justify-end bg-green-2'>
						<div className='m-02 bg-light cursor-pointer'>
							<SvgClose data-cy="ModalCoreClose" onClick={onClose} />
						</div>
					</div>
					<div
				 		tw='text-07 leading-12 text-light font-light bg-green-3 p-10'
						css={[ cssElementP ]}
					>
						{children}
					</div>
				</div>
			</div>
		</ModalCore>
	);
}
