import SummaryPage from 'components/pages/basket/SummaryPage';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const Index = () => (
	<div>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>

		<SummaryPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
