import { useQuery } from '@apollo/client';
import useLanguage from 'hooks/useLanguage';
import { QUERY } from 'interfaces/graph';

export default function useCountries() {
	const { language } = useLanguage();

	const { loading, error, data } = useQuery(QUERY.ALL_COUNTRIES, {
		fetchPolicy: 'cache-and-network',
		context: {
			headers: {
				'Accept-Language': language
			}
		}
	});

	return {
		countries: data?.allCountries?.edges?.map(item => item.node) ?? []
	};
}
