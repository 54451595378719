import { isBrowser } from 'utils/helper';

export default function useClientInfo(page, sourceEnum) {
	const initialClient = {
		sourceId: sourceEnum.sourceId,
		source: sourceEnum.source,
		page
	};

	if(!isBrowser) return { client: initialClient };

	const browserDetails = JSON.stringify({
		document: window.document,
		localStorage: window.localStorage,
		location: window.location,
		navigator: window.navigator,
		performance: window.performance,
		screen: window.screen,
		sessionStorage: window.sessionStorage,
	});

	const client = {
		browserDetails,
		referrer: document.referrer,
		sourceId: sourceEnum.sourceId,
		source: sourceEnum.source,
		page
	};

	return {
		client
	};
}
