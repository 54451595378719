import { Collapse } from '@mui/material';
import IconCheckmark from 'assets/pricing/circle-checkmark.inline.svg';
import SvgCopy from 'assets/pricing/copy.inline.svg';
import Form from 'components/base/Form';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import ButtonIconLeft from 'components/material-ui/ButtonIconLeft';
import Checkbox from 'components/material-ui/Checkbox';
import FormControlLabel from 'components/material-ui/FormControlLabel';
import Modal from 'components/material-ui/Modal';
import ModalCode from 'components/material-ui/ModalCode';
import SelectTextField from 'components/material-ui/SelectTextField';
import TextCodeFor from 'components/material-ui/TextCodeFor';
import TextController from 'components/material-ui/TextController';
import TextField from 'components/material-ui/TextField';
import { PRICING_PLAN } from 'components/pages/pricing/base/enumsPricing';
import { handleTranslateMonthsSelect } from 'components/pages/pricing/PricingPage';
import { ENUM_PAGE, ENUM_SOURCE } from 'constants/enums';
import { routes } from 'constants/routes';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useClientInfo from 'hooks/useClientInfo';
import useCommonData from 'hooks/useCommonData';
import useCountries from 'hooks/useCountries';
import useLanguage from 'hooks/useLanguage';
import useMutation from 'hooks/useMutation';
import parse from 'html-react-parser';
import { MUTATION, QUERY } from 'interfaces/graph';
import { usePersistState } from 'providers/PersistProvider';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { formatNthString, formatValueToEurope, isAnyEmpty, isBrowser, isTabletMobileScreen, scrollInto } from 'utils/helper';

const getPlanName = (id, durationInMonths) => {
	return `${PRICING_PLAN[id]?.toUpperCaseChar()}${durationInMonths}`;
};

export default function SummaryPage() {
	const { t } = useTranslation();
	const { language, currency } = useLanguage();
	const [errors, setErrors] = useState([]);
	const { client } = useClientInfo(ENUM_PAGE.FINTEQ, ENUM_SOURCE.PRICING_FORM);
	const { persistData, option } = usePersistState();
	const { planSelectionData } = useCommonData(QUERY.PLAN_SECTION_DATA);
	const { countries } = useCountries();
	const refPricingForm = useRef();
	const [showRegulationsModal, setShowRegulationsModal] = useState(false);

	const idPoland = countries?.find(country => country.code === 'PL')?.id;

	const [sendForm, mutationRes] = useMutation(MUTATION.PLAN_FORM, {
		onSuccess: () => {
			handleOpenSummaryCodeModal();
		},
		onErrors: setErrors
	});

	const planName = getPlanName(persistData.pricingPlan, option.durationInMonths);
	const vatTotal = (planSelectionData?.vatValue * persistData.totalCost / 100);
	const totalCostWithVat = (persistData.totalCost + vatTotal)?.toFixed(2);

	const formPricing = useFormik({
		initialValues: {
			country: '',
			firstName: '',
			email: '',
			phone: '',
			companyName: '',
			street: '',
			buildingNumber: '',
			postalCode: '',
			city: '',
			vat: true,
			vatValue: planSelectionData?.vatValue,
			// TODO: to payment method list
			paymentMethodId: '1',
			totalNetCost: persistData.totalCost?.toFixed(2),
			totalGrossCost: totalCostWithVat,
			nip: '',
			// all terms of service in one checkbox label
			termsOfServiceAccepted: false,
			planSetup: persistData.pricingPlan,
			planName: planName,
			extraOptionsIds: persistData?.basket?.map(item => item.id).toString(),
			duration: option.id,
			currency: persistData?.currency?.id,
			//	payment method
			bankAccountNumber: planSelectionData?.bankAccountNumber,
			recipient: planSelectionData?.recipient
		},
		enableReinitialize: true,
		onSubmit: ({ companyName, phone, ...values }) => {
			if (mutationRes.loading) return;

			let companyString = companyName;
			if(phone) {
				companyString = `${companyName} - Telefon: ${phone}`;
			}

			const dataToSend = {
				variables: {
					...client,
					...values,
					companyName: companyString,
					nip: values.vat ? values.nip : '',
					termsOfServiceAccepted: values.termsOfServiceAccepted,
					marketingConsentAccepted: values.termsOfServiceAccepted,
					termsOfServiceVersion: planSelectionData.termsOfServiceVersion,
					marketingConsentVersion: planSelectionData.marketingConsentVersion
				}
			};
			sendForm(dataToSend);
		}
	});

	useEffect(() => {
		if (!isAnyEmpty(errors) && formPricing.values.termsOfServiceAccepted) {
			scrollInto(refPricingForm);
		}
	}, [errors, formPricing]);


	useEffect(() => {
		if (isBrowser) {
			const handleBeforeUnload = function handleBeforeUnload(event) {
				const confirmationMessage = t('message.error.beforeunload.1') + t('message.error.beforeunload.2');

				(event || window.event).returnValue = confirmationMessage; //Gecko + IE
				return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
			};
			window.addEventListener('beforeunload', handleBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, []);

	useEffect(() => {
		if (!persistData?.basket) {
			navigate(routes[language].PRICING);
		}
	}, []);

	useEffect(() => {
		const shouldCheckPoland = language === 'pl';

		if (shouldCheckPoland) {
			formPricing.setFieldValue('country', idPoland);
		}
	}, [idPoland]);

	const handleCopyToClipboard = text => () => {
		if (isBrowser) {
			navigator.clipboard.writeText(text);
			toast.success(t('message.success.copyToClipboard'));
		}
	};

	const [isModalOpen, setModalOpen] = useState(false);

	const handleModalClose = () => {
		formPricing.resetForm();
		setModalOpen(false);
		navigate(routes[language].BECOME_AN_ANIMATOR);
	};

	const handleOpenSummaryCodeModal = () => {
		setModalOpen(true);
	};

	const handleOpenRegulationsModal = event => {
		event.preventDefault();
		setShowRegulationsModal(true);
	};

	const handleCloseRegulationsModal = () => {
		setShowRegulationsModal(false);
	};

	const TransMonths = handleTranslateMonthsSelect(language)(option.durationUnit, option.durationInMonths);
	const basketToString = persistData?.basket?.map(item => ` ${item.details.name}`).toString();
	const isPolandChecked = formPricing?.values?.country === idPoland;

	const handleSubmit = event => {
		formPricing.handleSubmit(event.preventDefault());
	};

	return (
		<Layout hideLanguage>
			<Modal
				isOpen={showRegulationsModal}
				onClose={handleCloseRegulationsModal}
			>
				{parse(planSelectionData?.termsOfServiceContent ?? '')}
			</Modal>

			<Form id={client.sourceId} className="relative border-t border-grey-E mt-24" onSubmit={handleSubmit}>
				<div className="sm:text-center mx-15 sm:mx-0">
					<h1 className="mb-14 mt-20 sm:mt-40 leading-25 sm:text-24">
						{t('pageBasket.summary.h1')}<span className="text-green-1 font-normal text-shadow-none">_</span>
					</h1>
				</div>

				<div ref={refPricingForm} style={{ maxWidth: '75rem' }} className="relative border-2 border-grey-E rounded-10 mx-15 xl:mx-auto mt-20 sm:mt-30 pt-05 sm:pt-18 pb-15 px-12 sm:px-30">
					<p className="text-12 font-bold mt-07 mb-05 sm:mb-10">{t('pageBasket.summary.sectionPurchaser')}</p>
					<div className="sm:w-82/100 lg:w-73/100 xl:w-55/100 grid grid-cols-2-auto gap-10 sm:gap-15">
						<SelectTextField
							tw="col-span-2 sm:col-span-1"
							name="country"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.country')}
							options={countries}
							onChange={() => {
								formPricing.setFieldValue('postalCode', '');
							}}
						/>
						<div className="hidden sm:block sm:col-span-1"/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="firstName"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.name')}
						/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="companyName"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.company')}
						/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="email"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.email')}
						/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="phone"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.phone')}
							parsingFunc={formatNthString}
							isNumberWithoutSigns
							isNumberString
						/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="street"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.street')}
						/>
						<TextField
							tw="col-span-2 sm:col-span-1"
							name="buildingNumber"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.apartment')}
						/>

						<TextField
							tw="col-span-2 sm:col-span-1"
							name="postalCode"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.postalCode')}
							mask={isPolandChecked ? '##-###' : null}
						/>
						<TextField
							tw="col-span-2 sm:col-span-1"
							name="city"
							form={formPricing}
							errors={errors}
							label={t('pageBasket.summary.formLabel.city')}
						/>

						<FormControlLabel
							tw="flex items-center"
							label={t('pageBasket.summary.formLabel.checkboxVat')}
							labelPlacement="end"
							control={
								<Checkbox
									tw="mr-05"
									name="vat"
									checked={formPricing.values.vat}
									onChange={formPricing.handleChange}
									errors={errors}
								/>
							}
						/>
						<div className="col-span-2 sm:col-span-1 -mt-05 sm:-mt-15 mb-20">
							<Collapse in={formPricing.values.vat}>
								<TextField
									fullWidth
									name="nip"
									form={formPricing}
									errors={errors}
									label={t('pageBasket.summary.formLabel.nip')}
								/>
							</Collapse>
						</div>
					</div>

					<p className="text-12 font-bold mt-07 mb-05 sm:mb-10">{t('pageBasket.summary.sectionPaymentMethod')}</p>
					<div>
						<FormControlLabel
							tw="flex items-center"
							label={<p>{t('pageBasket.summary.paymentMethod.bankTransfer')}<span className="text-green-1">FV proforma</span></p>}
							labelPlacement="end"
							control={
								<Checkbox
									tw="mr-05"
									onChange={() => {}}
									checked
								/>
							}
						/>
						<div className="sm:w-3/4 lg:w-2/3 xl:w-1/2 border border-grey-E rounded-10 mt-20 sm:mt-10 mb-30 sm:mb-24 pt-10 pb-15 px-10 sm:px-20">
							<p className="text-11 text-grey-7 border-b border-grey-E py-07">
								{t('pageBasket.summary.paymentMethod.toPay')}
								<span className="pointer whitespace-nowrap text-green-1 font-bold" onClick={handleCopyToClipboard(totalCostWithVat)}>
									<span data-cy="totalCostWithVat">{formatValueToEurope(totalCostWithVat, currency?.code)}</span>
									<SvgCopy tw="inline mx-05"/>
								</span>
							</p>
							<p className="text-11 text-grey-7 border-b border-grey-E py-07">
								{t('pageBasket.summary.paymentMethod.recipient')}
								<span className="pointer whitespace-nowrap text-green-1 font-bold" onClick={handleCopyToClipboard(planSelectionData?.recipient)}>
									{planSelectionData?.recipient}<SvgCopy tw="inline mx-05"/>
								</span>
							</p>
							<p className="text-11 text-grey-7 border-b border-grey-E py-07">
								{t('pageBasket.summary.paymentMethod.accountNumber')}
								<span className="pointer whitespace-nowrap text-green-1 font-bold" onClick={handleCopyToClipboard(planSelectionData?.bankAccountNumber)}>
									{planSelectionData?.bankAccountNumber}<SvgCopy tw="inline mx-05"/>
								</span>
							</p>
							<p className="text-11 text-grey-7 pt-07">
								{t('pageBasket.summary.paymentMethod.transferTitle')}
								<span className="pointer whitespace-nowrap text-green-1 font-bold" onClick={handleCopyToClipboard(planName)}>
									{planName}<SvgCopy tw="inline mx-05"/>
								</span>
							</p>
						</div>
					</div>

					<p className="text-12 font-bold mt-07 mb-05 sm:mb-10">{t('pageBasket.summary.sectionAgreement')}</p>
					<div>
						<FormControlLabel
							tw="flex items-start"
							label={
								<p className="text-10 text-grey-6">
									{t('pageBasket.summary.agreement.regulations.1')}
									<span className="text-green-1 underline" onClick={handleOpenRegulationsModal}>
										{t('pageBasket.summary.agreement.regulations.2')}
									</span>
									{/*<TooltipCode*/}
									{/*	clearSpan*/}
									{/*	interactive*/}
									{/*	placement="top"*/}
									{/*	leaveTouchDelay={120000}*/}
									{/*	TransitionComponent={Zoom}*/}
									{/*	onClick={formPricing.handleChange}*/}
									{/*	Content={<p className='text-08 font-semibold bg-primary'>{parse(planSelectionData?.marketingConsentContent ?? '')}</p>}>*/}
									{/*	<span className='text-green-1 underline'>*/}
									{/*		{t('pageBasket.summary.agreement.regulations.2')}*/}
									{/*	</span>*/}
									{/*</TooltipCode>*/}
									{t('pageBasket.summary.agreement.regulations.3')}
								</p>
							}
							labelPlacement="end"
							control={
								<Checkbox
									tw="mr-05 w-10 h-10 sm:w-10 sm:h-10"
									name="termsOfServiceAccepted"
									checked={formPricing.values.termsOfServiceAccepted}
									onChange={formPricing.handleChange}
									errors={errors}
									required
								/>
							}
						/>
					</div>
				</div>

				<ModalCode
					isOpen={isModalOpen}
					onClose={handleModalClose}
				>
					<TextController index={0} delay={3800} className="font-code-pro min-h-250 text-09 sm:text-11 p-05 sm:p-10">
						<TextCodeFor showCaretDuringDelay delay={1200}>{t('pageBasket.summary.modal.os.row.1', { name: formPricing.values.firstName })}</TextCodeFor>
						<TextCodeFor delayCaretHide={1200}>{t('pageBasket.summary.modal.os.row.2', { company: formPricing.values.companyName })}</TextCodeFor>
						<TextCodeFor>{t('pageBasket.summary.modal.os.row.3', {
							plan: PRICING_PLAN[persistData.pricingPlan]?.toUpperCaseChar(),
							months: t(TransMonths.props.i18nKey, { duration: TransMonths.props.values.duration })
						})}</TextCodeFor>
						<TextCodeFor>{t('pageBasket.summary.modal.os.row.4')}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={!persistData?.basket?.length}>{!persistData?.basket?.length && t('pageBasket.summary.modal.os.noOptions')}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 0}>{persistData?.basket?.length > 0 && persistData?.basket[0].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 1}>{persistData?.basket?.length > 1 && persistData?.basket[1].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 2}>{persistData?.basket?.length > 2 && persistData?.basket[2].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 3}>{persistData?.basket?.length > 3 && persistData?.basket[3].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 4}>{persistData?.basket?.length > 4 && persistData?.basket[4].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 5}>{persistData?.basket?.length > 5 && persistData?.basket[5].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 6}>{persistData?.basket?.length > 6 && persistData?.basket[6].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 7}>{persistData?.basket?.length > 7 && persistData?.basket[7].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 8}>{persistData?.basket?.length > 8 && persistData?.basket[8].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 9}>{persistData?.basket?.length > 9 && persistData?.basket[9].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 10}>{persistData?.basket?.length > 10 && persistData?.basket[10].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 11}>{persistData?.basket?.length > 11 && persistData?.basket[11].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 12}>{persistData?.basket?.length > 12 && persistData?.basket[12].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 13}>{persistData?.basket?.length > 13 && persistData?.basket[13].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 14}>{persistData?.basket?.length > 14 && persistData?.basket[14].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 15}>{persistData?.basket?.length > 15 && persistData?.basket[15].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 16}>{persistData?.basket?.length > 16 && persistData?.basket[16].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 17}>{persistData?.basket?.length > 17 && persistData?.basket[17].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 18}>{persistData?.basket?.length > 18 && persistData?.basket[18].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 19}>{persistData?.basket?.length > 19 && persistData?.basket[19].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 20}>{persistData?.basket?.length > 20 && persistData?.basket[20].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 21}>{persistData?.basket?.length > 21 && persistData?.basket[21].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 22}>{persistData?.basket?.length > 22 && persistData?.basket[22].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 23}>{persistData?.basket?.length > 23 && persistData?.basket[23].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 24}>{persistData?.basket?.length > 24 && persistData?.basket[24].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 25}>{persistData?.basket?.length > 25 && persistData?.basket[25].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 26}>{persistData?.basket?.length > 26 && persistData?.basket[26].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 27}>{persistData?.basket?.length > 27 && persistData?.basket[27].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 28}>{persistData?.basket?.length > 28 && persistData?.basket[28].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 29}>{persistData?.basket?.length > 29 && persistData?.basket[29].details?.name}</TextCodeFor>
						<TextCodeFor showCaretDuringDelay showPoint={persistData?.basket?.length > 30}>{persistData?.basket?.length > 30 && persistData?.basket[30].details?.name}</TextCodeFor>
						<TextCodeFor delayCaretHide={1200} tw="mb-10">{t('pageBasket.summary.modal.os.row.5', {
							netValue: formatValueToEurope(persistData.totalCost, currency?.code, { cutZeros: false }),
							grossValue: formatValueToEurope(totalCostWithVat, currency?.code, { cutZeros: false })
						})}</TextCodeFor>
						<TextCodeFor>{t('pageBasket.summary.modal.os.row.6')}</TextCodeFor>
						<TextCodeFor>{t('pageBasket.summary.modal.os.row.7', { recipient: planSelectionData?.recipient })}</TextCodeFor>
						<TextCodeFor>{t('pageBasket.summary.modal.os.row.8', { bankAccountNumber: planSelectionData?.bankAccountNumber })}</TextCodeFor>
						<TextCodeFor delayCaretHide={1200}>{t('pageBasket.summary.modal.os.row.9', { title: planName })}</TextCodeFor>
						<TextCodeFor delayCaretHide={600} tw="mt-10">{t('pageBasket.summary.modal.os.row.10')}</TextCodeFor>
						<TextCodeFor delayCaretHide={600}>{t('pageBasket.summary.modal.os.row.11')}</TextCodeFor>
						<TextCodeFor delayCaretHide={600}>{t('pageBasket.summary.modal.os.row.12', { email: formPricing.values.email })}</TextCodeFor>
						<TextCodeFor delayCaretHide={600}>{t('pageBasket.summary.modal.os.row.13')}</TextCodeFor>
						<TextCodeFor delayCaretHide={12000} className="mt-20" onAnimationEnd={() => {
						}}>
							{t(isTabletMobileScreen() ? 'modal.os.endMobile' : 'modal.os.end')}
						</TextCodeFor>
					</TextController>
				</ModalCode>

				<ButtonIconLeft name="btnApprove" tw="w-160 mx-auto mt-30 mb-60" type="submit" Icon={IconCheckmark} size="medium">
					{t('pageBasket.label.btnApprove')}
				</ButtonIconLeft>
			</Form>
			<Footer/>
		</Layout>
	);
}
